import React from 'react'
import Layout from '../../components/Layout'
import { HeroSimpleCentered } from '../../components/Global/Hero'

const TermsAndConditions = () => {
  return (
    <Layout>
      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Terms &amp;
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Conditions</span>
        </h2>
        <p className="mt-8 text-base leading-6 text-gray-500">
          Updated: 5th June 2020
        </p>
      </HeroSimpleCentered>
      <div className="px-4 mx-auto max-w-2xl pb-32">
        <p className="text-sm sm:text-base leading-6 text-gray-500">
          These are the conditions of hire of Chauffeur Me Ltd (hereinafter called the company), they form the basis of the contract under which the company agrees to hire its vehicles to the customer, hereinafter called the hirer.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Breakdown and Delays</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The company gives its advice on journey time in good faith, however, as a result of breakdown or traffic congestion, or other events beyond the reasonable control of the company, journeys may take longer than predicted and in those circumstances the company will not be liable for any loss or inconvenience suffered by the hirer as a result.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The company shall use reasonable endeavours to provide a Passenger Vehicle which is in good working order and of the type specified by the Customer (and in the event that such a Vehicle is not available, a reasonable alternative vehicle) within any time for so doing given by us or within a reasonable time.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          If a vehicle cannot be provided as a result of breakdown, the company will endeavour to sub-contract out the hire of the vehicle to another operator. If this is not achievable then the hirer will be informed that the hire cannot be completed and the company will not be liable or incur any costs relating to the hire.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Liability for Injury</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The company will not accept any liability for damage, injury or loss for any passenger standing up or walking around the vehicle whilst in motion.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Supplying Coaches with Additional Seating</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The Company reserves the right to supply a larger coach than that ordered for any journey and in this event no additional charge shall be made to the hirer unless the additional capacity is utilised.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Use of the Vehicle</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The hirer cannot assume the use of the vehicle between outward and return journeys, nor that will it remain at the destination of the hirers use unless this has been agreed with the company in advance.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">The Use of Other Operators Vehicles</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The Company reserves the right to substitute other operator’s vehicles in place of its own for any journey or part thereof.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Payment Terms</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The balance of the hire charge is payable by fourteen days prior to the date of hire, unless other payments terms are agreed.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Passenger Capacity &amp; Seatbelts</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          No passenger may be carried in access of the seating capacity of the vehicle and passengers must wear seatbelts provided at all times.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Drivers Hours Regulations</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The hours of operation for the driver are regulated by law and the hirer accepts the responsibility of ensuring the hire keeps to the hours and times agreed by the company. Neither the hirer nor any passenger shall delay or otherwise interrupt the journey in such a way that the driver is at risk of breaching regulations relating to drivers hours and duty time.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          At the time of booking, if the hire period exceeds twelve hours from pick up time to drop off then the customer will be informed of the restrictions on driver regulations.  Guidance will then be provided to the customer of timings that will ensure compliance with drivers’ hours. If any breach is likely to occur, the driver will inform the hirer that the coach must depart at the time stated. If the hirer does not wish to return at the stated time, then the driver will be forced to depart without the party.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Conduct of Passengers</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The driver is responsible for the safety of the vehicle at all times and as such may remove any passenger whose behaviour prejudices safety or is in breach of the public service vehicle (conduct of drivers passengers) regulation 1990. These regulations set out certain rights and responsibilities on all parties and full details of these can be obtained from the company on request. The hirer is responsible for any damage caused to the vehicle by any passenger for the duration of the hire (where the hire is to a sporting event, the hirer should be aware of the legal requirements of the possession of alcohol under the justice act NI 2011, the carrying or consumption of alcohol on our vehicles is strictly forbidden.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Returnable Additional Deposits</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The company reserves the right to insist upon a returnable additional deposit being provided by the hirer in addition to the hire charge. The deposit will be returned to the hirer following completion of the hire provided that no additional expenses were incurred by the company which were a direct result of the hirers, or his/her passengers’ actions.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Additional Charges</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The company reserves the right to impose additional charges on the hirer following completion of the hire if the passengers have left the vehicle in an unreasonable untidy condition or having required additional time or mileage which was not included in the original booking.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">No Smoking Policy</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          Hirers should note that this company operates a non-smoking policy on all of our company vehicles. This policy will be strictly enforced. Hirers must please bring this condition to the attention of all passengers intending to travel on the coach.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Airport or Ferry Transfers</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          On collecting passengers from airports, ferry ports, etc the coach will wait for one hour after the prearranged collection free of charge after that additional waiting time will be charged at the discretion of the company.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Passengers &amp; Lost Property</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          All vehicles hired by the company are subject to restrictions on carry luggage for statutory safety reasons. Large bulky items may not be able to be carried and the hirer should take all steps to notify the company in advance of such requirements. The company accepts any personal property of the hirer and their passengers on the understanding that it will take all reasonable steps to avoid loss or damage. The hirer should notify the company or the driver if items of exceptional value are to be carried on the vehicle. It is the hirers’ responsibility to minimise risk of loss when property is left unattended. The company will not be responsible for any damage to, or loss of personal property left in vehicles, howsoever caused. All articles of lost property recovered from the vehicle will be held at the company’s premises where the vehicle is based and will be subject to the current public service vehicle (lost property regulations). The company will provide details of this legislation on request.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Acceptance of Quotation</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          Implies acceptance of the above conditions.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Complaints</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          In the event of complaint about the company’s services, the hirer should endeavour to seek a solution at the time by seeking assistance from the driver, or from the company by calling our support staff on 028 9086 6888 if this has not provided a remedy, complaints should be submitted in writing within 14 days of termination of hire. The company shall reply to any complaints within 48 hours of receipt of same. Please contact our General Manager by calling 028 9086 6888 or by writing to him at our offices at Unit 1, Glenville Industrial Estate, Glenville Road, Newtownabbey, BT37 0TU or by email <a href="mailto:hello@chauffeurmeltd.com">hello@chauffeurmeltd.com</a>.
        </p>
      </div>
    </Layout>
  )
}

export default TermsAndConditions
